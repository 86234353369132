import Control from '@/js/Controls/Control'
import { ViewportDimensions } from '@/js/Helpers/ViewportDimensions'
import naja from "naja";

class MainMenuControl implements Control {
	private readonly menuBreakpoint = 992
	private viewport = new ViewportDimensions()
	private menuWrap = document.querySelector<HTMLElement>('.main-menu')
	private controlsForward = document.querySelectorAll<HTMLElement>('.js-menu-control')
	private controlsBack = document.querySelectorAll<HTMLElement>('.js-menu-back')
	private currentLevel = 1
	private initialized = false
	public level2menu = {
		wrap: document.querySelector<HTMLElement>('.main-menu__item--active .main-menu__list--level-2'),
		dropdown: document.querySelector<HTMLElement>('.main-menu__item--active .main-menu__item--dropdown'),
		dropdownWidth: 70 as const, // Magic constant in CSS
		dropdownItems: document.querySelectorAll<HTMLElement>('.main-menu__item--active .main-menu__item--dropdown'),
		items: document.querySelectorAll<HTMLElement>(
			'.main-menu__item--active .main-menu__item--level-2:not(.main-menu__item--dropdown):not(.main-menu__item--back):not(.main-menu__item--forward)'
		)
	}
	private readonly cssClasses = {
		expanded: 'js-menu-expanded',
		level: 'js-menu-on-level-',
		jsHide: 'js-hide'
	} as const

	public async initialize() {
		if (this.initialized) return

		if (window.screen.width >= this.menuBreakpoint) {
			const url = this.menuWrap?.dataset['load']
			if (!url) return
			await naja.makeRequest('GET', url, null, { history: false })
			this.initialized = true
		}

		this.controlsForward.forEach((forwardLink) => {
			forwardLink.addEventListener('click', (event) => {
				this.moveForward(forwardLink, event)
			})
		})

		this.controlsBack.forEach((backwardLink) => {
			backwardLink.addEventListener('click', (event) => {
				this.moveBackward(backwardLink, event)
			})
		})

		window.addEventListener('resize', () => {
			this.setMenuDropdown()
		})
		window.dispatchEvent(new Event('resize'))
	}

	private setMenuDropdown() {
		this.level2menu.items.forEach((item) => {
			item.classList.remove(this.cssClasses.jsHide)
		})

		if (this.viewport.getViewportWidth() >= this.menuBreakpoint) {
			let showDropdown = false
			const wrapWidth = this.level2menu.wrap ? this.level2menu.wrap.offsetWidth + 1 : 0 // + 1 because of rounding

			this.level2menu.dropdownItems.forEach((item) => {
				item.classList.add(this.cssClasses.jsHide)
			})

			let itemsWidth = 0

			this.level2menu.items.forEach((item) => {
				itemsWidth += item.offsetWidth
			})

			for (let step = this.level2menu.items.length; step > 0; step--) {
				if (
					(!showDropdown && itemsWidth > wrapWidth) ||
					(showDropdown && itemsWidth + this.level2menu.dropdownWidth > wrapWidth)
				) {
					itemsWidth -= this.level2menu.items[step - 1].offsetWidth
					this.showDropdownItem(this.level2menu.items[step - 1])
					showDropdown = true
				}
			}

			if (showDropdown) {
				this.level2menu.dropdown?.classList.remove(this.cssClasses.jsHide)
			}
		}
	}

	private showDropdownItem(element: HTMLElement) {
		element.classList.add(this.cssClasses.jsHide)
		document
			.querySelector<HTMLElement>('.js-dropdown-item-' + element.dataset.pageid)
			?.classList.remove(this.cssClasses.jsHide)
	}

	private moveForward(link: Element, event: MouseEvent) {
		if (this.viewport.getViewportWidth() < this.menuBreakpoint) {
			event.preventDefault()

			link.parentElement?.classList.add(this.cssClasses.expanded)

			// Important removeClass/addClass priority
			this.menuWrap?.classList.remove(`${this.cssClasses.level}${this.currentLevel++}`)
			this.menuWrap?.classList.add(`${this.cssClasses.level}${this.currentLevel}`)
		}
	}

	private moveBackward(link: Element, event: MouseEvent) {
		if (this.viewport.getViewportWidth() < this.menuBreakpoint) {
			event.preventDefault()

			// Important removeClass/addClass priority
			this.menuWrap?.classList.remove(`${this.cssClasses.level}${this.currentLevel--}`)
			this.menuWrap?.classList.add(`${this.cssClasses.level}${this.currentLevel}`)
			// this.menuWrap?.scrollTo(0,0)

			link.closest(`.${this.cssClasses.expanded}`)?.classList.remove(this.cssClasses.expanded)
		}
	}
}

export default new MainMenuControl()
